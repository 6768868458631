import { NavLink } from '@remix-run/react';
import { NotFoundPageIcon } from '@storefront/ui-components/assets/icons';
import { NavbarContent } from '~/components/navbar/NavbarContent';

export const NotFound = () => {
  return (
    <div className="w-full h-full">
      <NavbarContent />

      <section className="flex items-center flex-col">
        <p>Oops, looks like there’s nothing here.</p>
        <NavLink to="/" className="underline">
          Browse the latest auctions...
        </NavLink>
        <NotFoundPageIcon className="max-w-full" />
      </section>
    </div>
  );
};
