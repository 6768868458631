import type { HTMLAttributes } from 'react';

export default (props: HTMLAttributes<HTMLOrSVGElement>) => {
  return (
    <svg
      {...props}
      width="526"
      viewBox="0 0 526 480"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="not found icon"
    >
      <path
        d="M56.1806 230.78L195.471 183.26L357.85 279.814L218.559 327.334L56.1806 230.78Z"
        fill="#E3E5E9"
        stroke="black"
      />
      <path d="M195.709 182.595L54.9688 230.7L164.13 295.991L195.709 285.943V182.595Z" fill="#C8C7CA" stroke="black" />
      <path
        d="M56.181 332.938L195.471 285.419L357.85 381.915L218.559 429.434L56.181 332.938Z"
        fill="#E3E5E9"
        stroke="black"
      />
      <path
        d="M218.724 328.163L358.258 280.561L358.258 381.68L218.724 429.283L218.724 328.163Z"
        fill="#C8C7CA"
        stroke="black"
      />
      <path
        d="M233.844 384.483L279.329 368.966L279.329 401.706L233.844 417.223L233.844 384.483Z"
        fill="#F8F8F8"
        stroke="black"
      />
      <path
        d="M218.391 327.294L357.884 279.706L402.657 326.852L263.165 374.44L218.391 327.294Z"
        fill="#E3E5E9"
        stroke="black"
      />
      <path
        d="M14.0593 230.087L151.662 183.143L194.096 183.143L56.4933 230.087L14.0593 230.087Z"
        fill="#E3E5E9"
        stroke="black"
      />
      <path
        d="M55.4941 332.562L55.4941 231.488L218.002 328.06L218.002 429.135L55.4941 332.562Z"
        fill="#E3E5E9"
        stroke="black"
      />
      <path
        d="M1.38956 276.11L55.7761 230.487L218.373 327.113L163.987 372.736L1.38956 276.11Z"
        fill="#E3E5E9"
        stroke="black"
      />
      <path
        d="M196.228 183.284L249.469 183.284L411.294 279.451L358.053 279.451L196.228 183.284Z"
        fill="#E3E5E9"
        stroke="black"
      />
      <path d="M238.548 389.254L250.141 385.279V386.383L238.548 390.358V389.254Z" fill="#C8C7CA" />
      <path d="M238.548 395.515L266.763 386.383V387.487L238.548 396.619V395.515Z" fill="#C8C7CA" />
      <rect
        x="401.607"
        y="216.502"
        width="94.8597"
        height="15.7139"
        rx="7.85697"
        transform="rotate(-143.839 401.607 216.502)"
        fill="#C8C7CA"
        stroke="black"
      />
      <rect
        x="481.433"
        y="285.473"
        width="143.612"
        height="32.88"
        rx="16.44"
        transform="rotate(-143.839 481.433 285.473)"
        fill="#C8C7CA"
        stroke="black"
      />
      <circle
        cx="291.77"
        cy="129.638"
        r="85.2543"
        transform="rotate(-143.839 291.77 129.638)"
        fill="#C8C7CA"
        stroke="black"
      />
      <circle
        cx="291.771"
        cy="129.638"
        r="69.5877"
        transform="rotate(-143.839 291.771 129.638)"
        fill="white"
        stroke="black"
      />
      <path
        d="M275.214 76.5875C271.093 67.3364 306.25 70.5328 323.702 79.0785C341.155 87.6243 353.457 114.09 345.761 118.367C336.4 123.568 339.47 106.822 318.775 92.5142C291.892 73.9281 277.999 82.8393 275.214 76.5875Z"
        fill="#F3F4F6"
      />
      <circle
        cx="277.936"
        cy="168.678"
        r="21.6378"
        transform="rotate(-143.839 277.936 168.678)"
        fill="black"
        stroke="black"
      />
    </svg>
  );
};
